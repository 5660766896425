import React from "react";

const Footer = () => {
  return (
    <div className="font-lato text-sm md:w-1/4 w-3/4 m-auto text-center pb-8 text-neutral-900">
      “I see now that the circumstances of one’s birth are irrelevant… It is
      what you do with the gift of life that determines who you are.” -Mewtwo
    </div>
  );
};

export default Footer;
