import {Route, Routes, BrowserRouter as Router} from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";

import ErrorPage from "./components/molecules/404Page/ErrorPage";

import {
  ABOUT_PAGE,
  CONTACT_PAGE,
  PROJECT_PAGE,
  LIFE_PAGE,
  TESTIMONIALS_PAGE,
  RESOURCES_PAGE,
} from "./constants/constants";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={ABOUT_PAGE} element={<About />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
