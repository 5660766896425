import React from "react";

const BaseButton = (props) => {
  return (
    <button
      name={props.name}
      className={props.className}
      value={props.value}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default BaseButton;
