import React from "react";
import BaseButton from "../../atoms/buttons/BaseButton";
import {Link} from "react-router-dom";
import {
  ABOUT_PAGE,
  CONTACT_PAGE,
  PROJECT_PAGE,
  LIFE_PAGE,
  TESTIMONIALS_PAGE,
  RESOURCES_PAGE,
} from "../../../constants/constants";

import {NavLink} from "react-router-dom";

const Navbar = () => {
  return (
    <div className="font-medium m-auto space-x-6 my-4">
      <NavLink
        to="/"
        className={({isActive, isPending}) =>
          isPending
            ? "text-neutral-600  "
            : isActive
            ? "text-neutral-900 font-bold "
            : ""
        }
      >
        Home
      </NavLink>
      <NavLink
        to="/about"
        className={({isActive, isPending}) =>
          isPending
            ? "text-neutral-600  "
            : isActive
            ? "text-neutral-900 font-bold "
            : ""
        }
      >
        About
      </NavLink>
      {/*
        <BaseButton className="bg-neutral-50 md:px-12 px-2 py-1 w-fit font-medium rounded-md rounded-lg text-neutral-600 hover:bg-neutral-200 focus:bg-neutral-300 focus:text-neutral-600 ">
          <Link to={PROJECT_PAGE}>Portfolio</Link>
        </BaseButton>{" "}
       
      <BaseButton className=" md:px-12 px-2 py-1 w-fit font-medium rounded-md rounded-lg text-neutral-600 hover:bg-neutral-200 focus:bg-neutral-300 focus:text-neutral-600 ">
        <Link to={ABOUT_PAGE}>About</Link>
      </BaseButton>
   
        <BaseButton className="bg-neutral-50 md:px-8 px-2 py-2 w-fit font-medium  rounded-md border-b-4 border-r-4 border-2 border-neutral-500 rounded-lg bg-white text-neutral-600 hover:bg-neutral-200 focus:bg-neutral-300 focus:text-neutral-600 focus:border-2 focus:border-neutral-500">
          Contact
        </BaseButton> */}
      {/* <BaseButton className="bg-neutral-50 md:px-12 px-2 py-1 w-fit font-medium  rounded-md  rounded-lg text-neutral-600 hover:bg-neutral-200 focus:bg-neutral-300 focus:text-neutral-600 ">
          <Link to={RESOURCES_PAGE}>Resources</Link>
        </BaseButton> */}
    </div>
  );
};

export default Navbar;
