import React from "react";
import LandingPage from "./LandingPage";

const Home = () => {
  return (
    <div className="w-screen h-screen ">
      <LandingPage></LandingPage>
    </div>
  );
};

export default Home;
