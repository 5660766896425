import React from "react";
import BaseHeader1 from "../../components/atoms/typography/BaseHeader1";

import BaseImage from "../../components/atoms/image/BaseImage";

import BaseParagraph from "../../components/atoms/typography/BaseParagraph";
import mountain from "../../assets/mountain.JPG";
import Navbar from "../../components/molecules/Navbar/Navbar";
import gee1 from "../../assets/portfolio/gee1.png";
import richnessposter from "../../assets/portfolio/richnessposter.jpeg";
import cricketposter from "../../assets/portfolio/cricketposter.jpeg";

const About = () => {
  return (
    <div className=" text-neutral-900 h-fit w-full bg-center bg-cover text-center py-4 px-4 bg-neutral-50 ">
      <Navbar />
      <BaseHeader1 className=" text-4xl font-lato font-medium  text-sky-900 py-8">
        About Me
      </BaseHeader1>

      <div className="lg:w-1/3 w-11/12 m-auto text-left space-y-8 bg-neutral-50 px-4 pt-4 pb-8 rounded-2xl">
        <BaseImage className=" m-auto mb-4 h-3/5 w-3/5" src={mountain} />
        <p>
          I was born and raised in the US and was the first person in my family
          to attend university.
        </p>
        <p>
          My professional career began as an Environmental Researcher and
          Analyst. This role allowed me to develop strong skills in data
          analytics, research, and coding.
        </p>
        <p>
          Today, I am still using these skills (and more!) when working on
          SkinSort.
        </p>
        {/*} <p>
          I have also worked numerous other positions throughout my life that
          has taught me various skills.{" "}
        </p>
        <p>
          I've been restaurant staff, primary school tutor, non-profit intern,
          government intern, profesionally-trained mentor, website designer,
          part-time English teacher, lab tech, front-end developer and more.
  </p> */}
      </div>
      <div className="bg-neutral-100 px-4 l:pb-20 pb-40 lg:px-8">
        <div
          className=" lg:w-1/3 w-11/12 m-auto pt-8 text-left space-y-4"
          id="research"
        >
          <BaseHeader1 className="text-3xl text-center text-sky-900 m-auto pb-8 ">
            Snippets of My Portfolio
          </BaseHeader1>
          <BaseHeader1 className="text-sky-900 text-lg font-medium py-2 ">
            Deforestation in Asia
          </BaseHeader1>
          <BaseParagraph className="text-left">
            I used to work as a Research Assistant and Analyst on Deforestation
            in the Asian continent. My duties included compiling an extensive
            literature review, working with raster data for forest gain and
            loss, analyzing the data, and visualizing it.
          </BaseParagraph>
          <BaseImage className="pt-8 w-1/2 m-auto py-4" src={gee1} />
          <BaseParagraph className="text-left">
            This data was used for various reforesting projects in countries
            such as the Cambodia, Vietnam, and Indonesia.
          </BaseParagraph>
          <BaseHeader1 className="text-sky-900 text-lg font-medium pb-2 pt-6 ">
            Biodiversity in South Texas Cave System
          </BaseHeader1>
          <BaseParagraph className="text-left">
            In 2016, I was selected to become a McNair Scholar. This provided me
            with a stipend to conduct research for St.Mary’s University over the
            course of about two years.
          </BaseParagraph>{" "}
          <BaseParagraph className="text-left">
            I have been lucky enough to have presented my research at St. Mary’s
            University, The Texas Wildlife Conference, and UC Berkeley McNair
            Symposium.
          </BaseParagraph>{" "}
          <BaseParagraph>
            You can read my publication{" "}
            <a
              href="http://uis-speleo.org/wp-content/uploads/2020/08/Proceedings-17th-ICS-Vol1-Ed2-screen.pdf"
              target="_blank"
              className="text-sky-600"
            >
              {" "}
              here.
            </a>
          </BaseParagraph>
          <div className="flex flex-row space-x-4 py-4">
            <BaseImage className="w-1/2 m-auto" src={richnessposter} />
            <BaseImage className="w-1/2 m-auto" src={cricketposter} />
          </div>
          <BaseHeader1 className="text-sky-900 text-lg font-medium pb-2 pt-4 ">
            Web Developer Certificate
          </BaseHeader1>
          <BaseParagraph className="text-left">
            In 2021, I decided to enhance my development skills by attending
            coding school. Learning web development was difficult, but I
            persevered and earned my certificate!
          </BaseParagraph>{" "}
          <BaseHeader1 className="text-sky-900 text-lg font-medium pt-4">
            {" "}
            Publications{" "}
          </BaseHeader1>
          <BaseParagraph className="text-left">
            • Publications Seasonal Variations in Species Abundance and Impact
            of in Robber Baron Cave, Texas (Page 117)
          </BaseParagraph>
          <BaseParagraph className="text-left pt-2">
            • Does a Seasonal Distribution Pattern of the Cave Cricket,
            Ceuthophilus Cunicular, Exist Within the Robber Baron Cave System? -
            There is only a hardcopy currently available
          </BaseParagraph>
          <BaseHeader1 className="text-sky-900 text-lg font-medium pt-4 ">
            {" "}
            Interviews
          </BaseHeader1>
          <BaseParagraph className="text-left">
            {" "}
            •{" "}
            <a
              href="https://scienceandskincare.blog/2021/10/05/in-conversation-with-the-founders-of-skinsort/"
              target="_blank"
              className="underline hover:text-sky-600"
            >
              In conversation with the founders of SkinSort
            </a>
            <br></br> •{" "}
            <a
              href="https://www.goodrx.com/conditions/generalized-anxiety-disorder/mental-health-stigma-in-asian-communities"
              target="_blank"
              className="underline hover:text-sky-600"
            >
              Mind Over Stigma: How 3 Asian Americans Cope With Anxiety
            </a>{" "}
            by the wonderful Rebecca Samuelson
          </BaseParagraph>
          {/* <BaseParagraph className="text-left">
          Google Earth Engine Code for forest loss and gain in North America
</BaseParagraph> */}
        </div>
      </div>
    </div>
  );
};

export default About;
