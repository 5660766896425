import React from "react";
import baffin2 from "../../../assets/baffin2-min.jpg";
import BaseHeader1 from "../../../components/atoms/typography/BaseHeader1";
import Navbar from "../Navbar/Navbar";

const ErrorPage = () => {
  return (
    <div
      className=" text-neutral-900 h-screen lg:w-auto lg:bg-center bg-right lg:bg-center bg-cover text-center py-8"
      style={{backgroundImage: `url(${baffin2}`}}
    >
      <Navbar />
      <BaseHeader1 className=" text-4xl font-lato font-medium text-sky-900 py-10">
        You seem to be lost.
      </BaseHeader1>
    </div>
  );
};

export default ErrorPage;
