import React from "react";

import BaseHeader1 from "../../components/atoms/typography/BaseHeader1";

import {useTranslation} from "react-i18next";
import {useState} from "react";
import Navbar from "../../components/molecules/Navbar/Navbar";
import Footer from "../../components/molecules/Footer.jsx/Footer";

import WavesSVG from "../../components/atoms/image/WavesSVG";
import DarkModeButton from "../../components/molecules/Buttons/DarkModeButton";
import AccessibilityButton from "../../components/molecules/Buttons/AccessiblityButton";
import BaseButton from "../../components/atoms/buttons/BaseButton";
import {FriendsList} from "./FriendsList";
import baffin from "../../assets/baffin-min.jpg";

const LandingPage = () => {
  const {t} = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  // const [nightMode, setNightMode] = useState(false);
  // const [accessibleMode, setAccessibleMode] = useState(false);

  // const handleNightChange = () => {
  //   setNightMode((prevState) => !prevState);
  //   console.log(nightMode);
  // };

  // const handleAccessibleChange = () => {
  //   setAccessibleMode((prevState) => !prevState);
  // };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className="w-screen h-screen bg-transparent relative bg-neutral-50 pb-20 m-auto">
      {/* <div className="bg-transparent w-full bg-sky-100 flex flex-row">
        <DarkModeButton onClick=""> </DarkModeButton>
        <AccessibilityButton onClick=""></AccessibilityButton>
      </div> */}

      {/* top div */}
      <div
        className="p-4 bg-cover lg:bg-center bg-right bg-no-repeat bg-cover h-1/2 w-auto lg:h-3/4 text-center bg-neutral-50"
        style={{backgroundImage: `url(${baffin}`}}
      >
        <Navbar />
        <div className="flex md:flex-row flex-col m-auto w-full text-center justify-center pt-4 lg:pt-6 md:pb-2">
          <BaseHeader1 className="md:text-6xl text-5xl font-lato font-medium text-sky-900 ">
            {t("IM_GENNE")}
          </BaseHeader1>
          <button
            className="invisible md:visible"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            💬
          </button>
        </div>
        <div className="h-2">
          {isHovering && (
            <p className="text-sm text-sky-700">pronounced like 'Genie'</p>
          )}
        </div>
      </div>
      <div className="lg:w-1/3 w-11/12 m-auto text-left space-y-8 py-12 px-4 ">
        <p className=" ">
          I'm the co-founder of{" "}
          <a
            href="https://www.skinsort.com/"
            className="hover:text-sky-700 underline hover:font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            SkinSort
          </a>
          .
        </p>
        <p className="">
          SkinSort has been the biggest challenge, yet the most fulfilling
          endeavor I've ever taken on. It has required thousands of hours,
          boundless passion, some tears, and a lot of love from me.
        </p>
        <p className="">Watching it grow has made every moment worth it.</p>

        {/* <IconLine className="flex flex-row justify-center font-vollkorn md:text-xl text-lg md:text-sky-900 invisible md:visible md:space-x-20 md:pb-48 md:pt-8"></IconLine> */}
      </div>
      {/*  <div className="p-4 bg-neutral-50 md:mt-8">
        <Toolbox />
        <div className="lg:auto lg:w-1/2 xl:w-1/2 py-6 bg-cover m-auto border-2 border-sky-800 rounded-lg bg-white"> 
      </div>*/}

      <div className=" m-auto space-y-4 bg-neutral-100 w-screen">
        {/* about | projects | life */}
        <div className="bg-neutral-50">{/* <Hats /> */}</div>
        <div className="">
          <div className="m-auto w-auto text-center ">
            <BaseHeader1 className="text-3xl text-sky-900 m-auto pt-12 pb-8 px-4">
              Unbiased reviews from my best friends
            </BaseHeader1>
            <div className="md:grid md:grid-cols-2 md:gap-8 p-4 md:w-11/12 m-auto">
              {FriendsList.map((field) => (
                <div
                  className=" bg-neutral-50 rounded-2xl w-11/12 h-44 md:h-64 m-auto md:p-4 mb-4 md:mb-0 "
                  key={field.name}
                >
                  <div className="flex flex-row columns-2">
                    <div className="md:w-1/4 w-1/2 m-auto pl-8 md:pl-0 md:ml-4">
                      <img
                        className="rounded-full bg-cover mt-4 ml-8 md:ml-0 md:w-full md:h-full  w-1/2 h-1/2"
                        src={field.image}
                        alt={field.name}
                      />
                      <div className=" my-2 text-center flex flex-col text-center ">
                        <div className="font-bold md:text-base text-sm text-neutral-800">
                          {field.name}
                        </div>
                        <div className="md:text-sm text-xs mb-2 text-neutral-500">
                          {field.location}
                        </div>
                      </div>
                    </div>

                    <div className="text-neutral-700 text-small py-4 px-2 lg:mx-2 md:mx-2 mx-0 md:px-0 ml-8 mt-4 text-sm h-32 md:h-44 md:w-2/3 w-3/4 overflow-y-scroll overscroll-contain">
                      {field.testimony}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <p className="font-vollkorn w-1/2"></p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
