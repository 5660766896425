import Anita from "../../assets/Anita.jpg";
import Will from "../../assets/Will.jpg";
import Sedrick from "../../assets/Sedrick.jpg";
import Joel from "../../assets/Joel.jpg";
import Mario from "../../assets/Mario.jpg";
import Erica from "../../assets/Erica.jpg";

import Cheyenne from "../../assets/cheyenne.jpg";
import Luna from "../../assets/luna.jpg";

export const FriendsList = [
  {
    name: "Anita S.",
    location: "Yogykarta, Indonesia",
    testimony:
      "I’ve known Genne for 8 years. She is my close friend. She has good personality. She is humble. She is so wise everytime that she need to solve her problems. She is honest. She always shows her best and responsible about her career. For the first time I know about Genne. I am so amazed when she talk about her passion. When she talk how to do work hard to get her dream job. She is enthusiastic and creative. Genne has excellent communication skills. Which helps her to communicate with people around her and around the world during her travel.",
    image: `${Anita}`,
  },
  {
    name: "Erica S.",
    location: "Illinois, USA",
    testimony:
      "Genne is self-driven and capable of anything she sets her sights on. Her adventurous spirit is upheld with a thick layer of resilience and adaptability, evidenced by her travels and her continual exploration and betterment of herself, her skills, and her ambitions. With this, she is also amiable - she posseses the ability to befriend and work with people across cultures, distances, and all walks of life. She's a bright spot in my life, someone I look up to for inspiration as much as for joy, and I am certain she will be an asset to any team she joins. ",
    image: `${Erica}`,
  },
  {
    name: "Will G.",
    location: "New York, USA",
    testimony:
      "I met Genne as a student on a study abroad trip to New Zealand. She was one of many however she stood out as seemingly more insightful into the local culture/geography than others. That’s what drew me in as I thought along the same lines. Over the 2 weeks and then few years after that outside of New Zealand I saw her as a big sister to confide things in. She’s kind maybe to a fault in making sure everyone’s fine before herself. She’s adaptable, able to jump in any new situation with a open mind and open view. She’s perseverant, fighting through the meanders of life, and certainly a unorthodox life path. And lastly she’s adventurous. To plan world trips, whether it’s days or months, cities or mountains, developed or developing areas, she has a idea of what to do and she’s stubborn enough to execute it where others would fail. That’s my view of Genne.",
    image: `${Will}`,
  },
  {
    name: "Joel G.",
    location: "Texas, USA",
    testimony:
      "Genne is very empathetic, supportive, and hard working close friend of mine. She tries to uplift her friends, family, and coworkers so that they can perform at their best. She's always educating herself on the latest tech, environment, and world news.",
    image: `${Joel}`,
  },
  {
    name: "Cheyenne L.",
    location: "Wyoming, USA",
    testimony:
      "An adventurous soul with a passion for the environment, Genne has succeeded in a wide variety of roles and continues to pursue the betterment of her professional life. Her co-owned business, SkinSort, has been developed from concept to reality in part due to her dedication and tenacity to make the business succeed. I believe that Genne will continue to achieve great things both in her career and out. ",
    image: `${Cheyenne}`,
  },
  {
    name: "Mario R.",
    location: "Texas, USA",
    testimony:
      "Can I send one in later and have this edited? Lol, I’m at work. If not, let it be known Genne is an inspiration to all humankind",
    image: `${Mario}`,
  },
  {
    name: "Sedrick Y.",
    location: "Texas, USA",
    testimony:
      "Genne is probably gonna be one of your best workers, she’s one of the most socially and analytically intelligent people I know!",
    image: `${Sedrick}`,
  },
  {
    name: "Luna L.",
    location: "Texas, USA",
    testimony:
      "Genne feeds me good dog food and takes me on walks twice a day. Best owner ever!",
    image: `${Luna}`,
  },
];
